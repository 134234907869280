export function getLastShotButtons() {
  return [
    {
      label: 'Serve',
      value: 'serve'
    },
    {
      label: 'Return',
      value: 'return'
    },
    {
      label: 'Forehand',
      value: 'forehand'
    },
    {
      label: 'Backhand',
      value: 'backhand'
    },
    {
      label: 'Volley',
      value: 'volley'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ];
}

export function getOutcomeButtons() {
  return [
    {
      label: 'Winner',
      value: 'winner'
    },
    {
      label: 'Unforced',
      value: 'unforced'
    },
    {
      label: 'Forced',
      value: 'forced'
    }
  ];
}

export function getOutTypeButtons() {
  return [
    {
      label: 'Net',
      value: 'net'
    },
    {
      label: 'Out',
      value: 'out'
    }
  ];
}

export function getSituationButtons(player1, player2) {
  return [
    {
      label: 'Baseline',
      value: 'baseline'
    },
    {
      label: `${player1} At Net`,
      value: 'player1_at_net'
    },
    {
      label: `${player2} At Net`,
      value: 'player2_at_net'
    },
    {
      label: 'Both At Net',
      value: 'both_at_net'
    },
    {
      label: 'Serving',
      value: 'serving'
    },
    {
      label: 'Returning',
      value: 'returning'
    }
  ];
}

export function getCauseOfErrorInputs() {
  return [
    {
      value: 'cause_of_error_other_input'
    }
  ];
}

export function getCauseOfErrorButtons() {
  return [
    {
      label: 'Human Input',
      value: 'human_input'
    },
    {
      label: 'Ball And Player Tracking',
      value: 'ball_and_player_tracking'
    },
    {
      label: 'Out of Sync',
      value: 'out_of_sync'
    },
    {
      label: 'Stream',
      value: 'stream'
    },
    {
      label: 'Level 1 Feed',
      value: 'level1_feed'
    },
    {
      label: 'Multiple',
      value: 'multiple'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ];
}

export function getIsDisabled(formData, fieldName, key, isReview, rallyLengthCount) {
  const { point_outcome, out_type, last_shot_type } = formData;

  // **Condition 1:** Disable 'out_type' if point_outcome is 'winner'
  const disableOutTypeIfWinner = point_outcome === 'winner' && fieldName === 'out_type';

  // **Condition 2:** Disable 'winner' in 'point_outcome' if out_type is 'net' or 'out'
  const disableWinnerOutcomeIfOutType =
    (out_type === 'net' || out_type === 'out') && fieldName === 'point_outcome' && key === 'winner';

  // **Condition 3:** Disable 'situation' buttons if last_shot_type is 'serve' or 'return' and not in review
  const disableSituationButtonsIfServeOrReturn =
    !isReview && (last_shot_type === 'serve' || last_shot_type === 'return') && fieldName === 'situation';

  // **Condition 4:** When rallyLengthCount is 0 in 'last_shot_type' field, disable all buttons except 'serve' and 'other'
  const disableLastShotTypeButtonsAtZero =
    rallyLengthCount === 0 && fieldName === 'last_shot_type' && key !== 'serve' && key !== 'other';

  // **Condition 5:** When rallyLengthCount is 0 in 'situation' field, disable all buttons except 'serving'
  const disableSituationButtonsAtZero = rallyLengthCount === 0 && fieldName === 'situation' && key !== 'serving';

  // **Condition 6:** When rallyLengthCount is 1 in 'last_shot_type' field, disable all buttons except 'serve', 'return', and 'other'
  const disableLastShotTypeButtonsAtOne =
    rallyLengthCount === 1 && fieldName === 'last_shot_type' && key !== 'serve' && key !== 'return' && key !== 'other';

  // **Condition 7:** When rallyLengthCount is 1 in 'situation' field, disable all buttons except 'serving' and 'returning'
  const disableSituationButtonsAtOne =
    rallyLengthCount === 1 && fieldName === 'situation' && key !== 'serving' && key !== 'returning';

  // **Condition 8:** When rallyLengthCount is 2 in 'last_shot_type' field, disable 'serve'
  const disableServeAtTwo = rallyLengthCount === 2 && fieldName === 'last_shot_type' && key === 'serve';

  // **Condition 9:** When rallyLengthCount is 2 in 'situation' field, disable 'serving'
  const disableServingAtTwo = rallyLengthCount === 2 && fieldName === 'situation' && key === 'serving';

  // **Condition 10:** When rallyLengthCount is 2 and last_shot_type is 'return', disable all 'point_outcome' buttons except 'winner'
  const disablePointOutcomeButtonsAtTwoReturn =
    rallyLengthCount === 2 && last_shot_type === 'return' && fieldName === 'point_outcome' && key !== 'winner';

  // **Condition 11:** When rallyLengthCount is 2 and point_outcome is 'winner', disable all 'last_shot_type' buttons except 'return'
  const disableLastShotTypeButtonsAtTwoWinner =
    rallyLengthCount === 2 && point_outcome === 'winner' && fieldName === 'last_shot_type' && key !== 'return';

  const shouldDisable =
    disableOutTypeIfWinner ||
    disableWinnerOutcomeIfOutType ||
    disableSituationButtonsIfServeOrReturn ||
    disableLastShotTypeButtonsAtZero ||
    disableSituationButtonsAtZero ||
    disableLastShotTypeButtonsAtOne ||
    disableSituationButtonsAtOne ||
    disableServeAtTwo ||
    disableServingAtTwo ||
    disablePointOutcomeButtonsAtTwoReturn ||
    disableLastShotTypeButtonsAtTwoWinner;

  return shouldDisable;
}
