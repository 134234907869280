import { useState, useEffect, useRef } from 'react';
import {
  Box,
  Alert,
  Flex,
  Button,
  Text,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  useToast
} from '_shared/designSystem/components';
import NextPointModalBody from './NextPointModalBody';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {
  getIsDisabled,
  getLastShotButtons,
  getOutcomeButtons,
  getOutTypeButtons,
  getSituationButtons,
  getCauseOfErrorButtons,
  getCauseOfErrorInputs
} from 'collect/util/collectionFormUtil';
import { collectFieldType } from '_shared/constants/collectionTypes';

export default function CollectionForm(props) {
  const {
    formData,
    player1Name,
    player2Name,
    updateFormData,
    submitCollectionItem,
    submitCannotCollectItem,
    handleNextOrSubmit,
    handleCannotReviewItem,
    reviewing,
    submittedRallyLength,
    rallyLengthCount,
    setRallyLengthCount,
    showNextPointLoading
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = (fieldName, value) => {
    if (!reviewing) {
      submitCollectionItem(fieldName, value);
    } else {
      updateFormData(fieldName, value);
    }
  };

  const handleTextChange = (fieldName, value) => {
    if (!reviewing) {
      submitCollectionItem(fieldName, value);
    } else {
      updateFormData(fieldName, value);
    }
  };

  const handleRallyLengthSubmit = () => submitCollectionItem('rally_length');

  const onChange = (event) => setRallyLengthCount(event.target.value);

  if (isEmpty(formData))
    return (
      <Box>
        <Alert message="Unable to create collection buttons" status="info" />
      </Box>
    );

  const ConfirmationModal = () => {
    return (
      <Modal isOpen={isModalOpen} size="lg">
        <ModalOverlay />
        <ModalContent>
          <NextPointModalBody
            onClose={() => setIsModalOpen(!isModalOpen)}
            handleContinue={
              reviewing
                ? () => {
                    handleCannotReviewItem();
                    setIsModalOpen(!isModalOpen);
                  }
                : () => {
                    submitCannotCollectItem();
                    setIsModalOpen(!isModalOpen);
                  }
            }
            text={reviewing ? 'Are you sure you cannot review?' : 'Are you sure you cannot collect?'}
          />
        </ModalContent>
      </Modal>
    );
  };

  return (
    <>
      <ConfirmationModal />
      <Box w="100%">
        <Flex justify="center">
          <Box mb={5}>
            <Box position="relative" width="550px">
              <Flex justifyContent="center" gap="50px">
                <NextPointOrSubmitReviewButton
                  onClick={() => handleNextOrSubmit()}
                  label={reviewing ? 'Submit Review' : 'Next Point'}
                  isLoading={showNextPointLoading}
                />
              </Flex>
              <Box position="absolute" right="0" top="0">
                <CannotReviewOrCollectButton
                  onClick={() => setIsModalOpen(!isModalOpen)}
                  label={`Cannot ${reviewing ? 'Review' : 'Collect'}`}
                />
              </Box>
            </Box>
          </Box>
        </Flex>
        <Flex gap="50px" flexWrap="wrap">
          <ShotsForm
            formData={formData}
            handleRallyLengthSubmit={handleRallyLengthSubmit}
            updateFormData={updateFormData}
            onChange={onChange}
            reviewing={reviewing}
            submittedRallyLength={submittedRallyLength}
            rallyLengthCount={rallyLengthCount}
            setRallyLengthCount={setRallyLengthCount}
          />
          <ButtonsColumn
            fieldName={collectFieldType.LAST_SHOT_TYPE}
            formData={formData}
            heading="Last Shot"
            buttonData={getLastShotButtons()}
            handleButtonClick={handleButtonClick}
            rallyLengthCount={rallyLengthCount}
          />
          <Flex direction="column" gap="22px">
            <ButtonsColumn
              fieldName={collectFieldType.POINT_OUTCOME}
              formData={formData}
              heading="Outcome"
              buttonData={getOutcomeButtons()}
              handleButtonClick={handleButtonClick}
              rallyLengthCount={rallyLengthCount}
            />
            <ButtonsColumn
              fieldName={collectFieldType.OUT_TYPE}
              formData={formData}
              heading="Out Type"
              buttonData={getOutTypeButtons()}
              handleButtonClick={handleButtonClick}
              rallyLengthCount={rallyLengthCount}
            />
          </Flex>
          <ButtonsColumn
            fieldName={collectFieldType.SITUATION}
            formData={formData}
            heading="Situation"
            buttonData={getSituationButtons(player1Name, player2Name)}
            handleButtonClick={handleButtonClick}
            rallyLengthCount={rallyLengthCount}
          />
          {reviewing && (
            <Flex direction="column" gap="22px">
              <ButtonsColumn
                fieldName={collectFieldType.CAUSE_OF_ERROR}
                formData={formData}
                heading="Cause of Error"
                buttonData={getCauseOfErrorButtons()}
                handleButtonClick={handleButtonClick}
                rallyLengthCount={rallyLengthCount}
              />
              {formData.cause_of_error === 'other' && (
                <TextInput
                  fieldName={collectFieldType.CAUSE_OF_ERROR_OTHER_INPUT}
                  formData={formData}
                  handleTextChange={handleTextChange}
                  inputData={getCauseOfErrorInputs()}
                />
              )}
            </Flex>
          )}
        </Flex>
      </Box>
    </>
  );
}

CollectionForm.propTypes = {
  formData: PropTypes.object,
  player1Name: PropTypes.string,
  player2Name: PropTypes.string,
  updateFormData: PropTypes.func,
  submitCollectionItem: PropTypes.func,
  submitCannotCollectItem: PropTypes.func,
  handleNextOrSubmit: PropTypes.func,
  handleCannotReviewItem: PropTypes.func,
  reviewing: PropTypes.bool,
  submittedRallyLength: PropTypes.bool,
  rallyLengthCount: PropTypes.number
};

const ButtonsColumn = ({ fieldName, heading, buttonData, formData, handleButtonClick, rallyLengthCount }) => {
  const url = window.location.href;
  const isReviewing = url.includes('review');
  return (
    <Box w={{ base: '100%', md: '150px' }}>
      <Flex direction="column" gap={5}>
        <Text fontSize="xl" fontWeight="semibold" textAlign="center">
          {heading}
        </Text>
        <Flex flexWrap="wrap" direction={{ base: 'row', md: 'column' }} gap={5}>
          {buttonData.map((item) => (
            <CollectionButton
              selected={formData?.[fieldName] === item.value}
              key={item.value}
              label={item.label}
              onClick={() => handleButtonClick(fieldName, item.value)}
              isDisabled={getIsDisabled(formData, fieldName, item.value, isReviewing, rallyLengthCount)}
            />
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};

const TextInput = ({ fieldName, inputData, formData, handleTextChange, placeholder }) => (
  <Box w={{ base: '100%', md: '150px' }}>
    <Flex direction="column" gap={5}>
      {inputData.map((item) => (
        <Input
          type="text"
          value={formData[fieldName] || ''}
          onChange={(e) => handleTextChange(fieldName, e.target.value)}
          placeholder={placeholder}
          key={item.value}
        />
      ))}
    </Flex>
  </Box>
);

const CollectionButton = ({ label, onClick, selected, isDisabled }) => (
  <Button size="sm" onClick={onClick} minW="80px" variant={selected ? 'outline' : 'solid'} isDisabled={isDisabled}>
    {label}
  </Button>
);

const ShotsForm = ({
  handleRallyLengthSubmit,
  formData,
  updateFormData,
  onChange,
  reviewing,
  submittedRallyLength,
  rallyLengthCount,
  setRallyLengthCount
}) => {
  const toast = useToast();

  const isServing = formData.situation === 'serving';
  const isLastShotReturn = formData.last_shot_type === 'return';

  // Refs to keep track of previous situation and last_shot_type
  const prevSituationRef = useRef(formData.situation);
  const prevLastShotRef = useRef(formData.last_shot_type);

  useEffect(() => {
    if (prevLastShotRef.current !== 'return' && formData.last_shot_type === 'return') {
      if (rallyLengthCount === 0) {
        toast({
          title: 'Invalid Shots Count',
          description: 'Shots must be greater than 0 when last shot is return.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right'
        });
        setRallyLengthCount(1);
      }
    }
    if (prevSituationRef.current !== 'serving' && formData.situation === 'serving') {
      if (rallyLengthCount > 1) {
        toast({
          title: 'Invalid Shots Count',
          description: 'Shots must be 0 or 1 when serving.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right'
        });
        setRallyLengthCount(1);
      }
    }

    // Update the previous refs
    prevSituationRef.current = formData.situation;
    prevLastShotRef.current = formData.last_shot_type;
  }, [formData.situation, formData.last_shot_type, rallyLengthCount, toast, setRallyLengthCount]);

  const isIncrementDisabled = (isServing && rallyLengthCount >= 1) || (isLastShotReturn && rallyLengthCount >= 2);

  const isDecrementDisabled = rallyLengthCount <= 0 || (isLastShotReturn && rallyLengthCount === 1);

  const handleDecrement = () => {
    if (isDecrementDisabled) return;

    if (isLastShotReturn && rallyLengthCount === 1) {
      toast({
        title: 'Invalid Operation',
        description: 'Shots must be greater than 0 when last shot is return.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      });
      return;
    }

    setRallyLengthCount(rallyLengthCount - 1);
  };

  const handleIncrement = () => {
    if (isIncrementDisabled) {
      if (isServing && rallyLengthCount >= 1) {
        toast({
          title: 'Maximum Shots Reached',
          description: 'Shots must be 0 or 1 when serving.',
          status: 'warning',
          duration: 3000,
          isClosable: true,
          position: 'top-right'
        });
      } else if (isLastShotReturn && rallyLengthCount >= 2) {
        toast({
          title: 'Maximum Shots Reached',
          description: 'Shots cannot exceed 2 when the last shot is return.',
          status: 'warning',
          duration: 3000,
          isClosable: true,
          position: 'top-right'
        });
      }
      return;
    }
    if (isLastShotReturn && rallyLengthCount < 1) {
      toast({
        title: 'Invalid Shots Count',
        description: 'Shots must be greater than 0 when last shot is return.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      });
      return;
    }

    setRallyLengthCount(rallyLengthCount + 1);
  };

  const handleInputChange = (event) => {
    let value = event.target.value;

    // Convert the input to a number
    value = parseInt(value, 10);

    // If the input is not a number or less than 0, default to 0
    if (isNaN(value) || value < 0) {
      value = 0;
    }

    if (isServing && value > 1) {
      toast({
        title: 'Invalid Input',
        description: 'Shots must be 0 or 1 when serving.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      });
      value = 1;
    }

    if (isLastShotReturn && value < 1) {
      toast({
        title: 'Invalid Input',
        description: 'Shots must be greater than 0 when last shot is return.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      });
      value = 1;
    }

    if (isLastShotReturn && value > 2) {
      toast({
        title: 'Maximum Shots Reached',
        description: 'Shots cannot exceed 2 when the last shot is return.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      });
      value = 2;
    }

    setRallyLengthCount(value);
  };

  return (
    <Box w="150px">
      <Flex direction="column" gap={5}>
        <Text fontSize="xl" fontWeight="semibold" textAlign="center">
          Shots
        </Text>
        {!reviewing ? (
          <Box>
            <Flex gap={2} mb={4} align="center">
              <Button onClick={handleDecrement} aria-label="Decrement Rally Length" isDisabled={isDecrementDisabled}>
                -
              </Button>

              <Input
                onChange={handleInputChange}
                type="number"
                value={rallyLengthCount}
                aria-label="Rally Length Count"
              />

              <Button
                onClick={handleIncrement}
                isDisabled={(isServing && rallyLengthCount >= 1) || (isLastShotReturn && rallyLengthCount >= 2)}
                aria-label="Increment Rally Length"
              >
                +
              </Button>
            </Flex>

            {/* Submit Button */}
            <Button
              variant={submittedRallyLength ? 'outline' : 'solid'}
              onClick={() => handleRallyLengthSubmit()}
              size="sm"
              minW="80px"
              w="100%"
              aria-label="Submit Rally Length"
            >
              Submit
            </Button>
          </Box>
        ) : (
          <Flex gap={2} align="center">
            <Button
              onClick={() => {
                if (formData?.rally_length > 0) {
                  if (formData.last_shot_type === 'return' && formData.rally_length === 1) {
                    toast({
                      title: 'Invalid Operation',
                      description: 'Shots must be greater than 0 when last shot is return.',
                      status: 'error',
                      duration: 5000,
                      isClosable: true,
                      position: 'top-right'
                    });
                    return;
                  }
                  updateFormData(collectFieldType.RALLY_LENGTH, parseInt(formData?.rally_length) - 1);
                }
              }}
              aria-label="Decrement Rally Length"
            >
              -
            </Button>

            <Input
              onChange={(event) =>
                updateFormData(collectFieldType.RALLY_LENGTH, convertRallyLengthInput(event.target.value))
              }
              type="number"
              value={convertRallyLength(formData?.rally_length)}
              aria-label="Rally Length Count"
            />

            <Button
              onClick={() => {
                if (!isServing || parseInt(formData?.rally_length) < 1) {
                  updateFormData(collectFieldType.RALLY_LENGTH, parseInt(formData?.rally_length) + 1 || 0);
                } else {
                  toast({
                    title: 'Maximum Shots Reached',
                    description: 'Shots must be 0 or 1 when serving.',
                    status: 'warning',
                    duration: 3000,
                    isClosable: true,
                    position: 'top-right'
                  });
                }
              }}
              // **Update isDisabled Prop in Reviewing Mode (if needed)**
              isDisabled={isServing && parseInt(formData?.rally_length) >= 1}
              aria-label="Increment Rally Length"
            >
              +
            </Button>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

function convertRallyLength(num) {
  if (num === '') return '';
  if (isNaN(num)) return '';
  if (num === null || num === undefined) return '';
  return num;
}

function convertRallyLengthInput(num) {
  if (num === '') return null;
  return parseInt(num);
}

const NextPointOrSubmitReviewButton = ({ onClick, label, isLoading }) => {
  return (
    <Button onClick={onClick} size="sm" w="250px" isLoading={isLoading}>
      {label}
    </Button>
  );
};

const CannotReviewOrCollectButton = ({ onClick, label }) => {
  return (
    <Button onClick={onClick} size="sm" bg="red.500" _hover={{ bg: 'red.600' }}>
      {label}
    </Button>
  );
};
