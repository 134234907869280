import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Badge } from '_shared/designSystem/components';

const FIVE_MINUTES = 5 * 60 * 1000;

const CountdownBadge = ({ flaggedTime, countdownTime = FIVE_MINUTES }) => {
  const timeFlagged = flaggedTime || sessionStorage.getItem('flaggedTime');
  const flaggedTimestamp = new Date(timeFlagged).getTime();
  // eslint-disable-next-line no-unused-vars
  const [remainingTime, setRemainingTime] = useState(countdownTime - (Date.now() - flaggedTimestamp));
  const [statusText, setStatusText] = useState('');
  const [color, setColor] = useState('grey');

  useEffect(() => {
    let interval;

    const updateCountdown = () => {
      const now = Date.now();
      const elapsed = now - flaggedTimestamp;
      const newRemainingTime = countdownTime - elapsed;

      if (newRemainingTime > 0) {
        setRemainingTime(newRemainingTime);
        setStatusText(formatTime(newRemainingTime));

        if (newRemainingTime <= 60 * 1000) {
          setColor('error');
        } else if (newRemainingTime <= 2 * 60 * 1000) {
          setColor('warning');
        } else {
          setColor('grey');
        }
      } else {
        setStatusText('Over 5 mins');
        setColor('error');
        clearInterval(interval);
      }
    };

    updateCountdown();
    interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, [countdownTime, flaggedTimestamp]);

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.max(Math.floor(milliseconds / 1000), 0);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')} mins`;
  };

  return <Badge text={statusText} colour={color} />;
};

CountdownBadge.propTypes = {
  flaggedTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.number]).isRequired,
  countdownTime: PropTypes.number
};

export default CountdownBadge;
