import React from 'react';
import { Box, Table, Tbody, Td, Th, Thead, Tr, Alert, Toast } from '_shared/designSystem/components';
import { mainAreaMaxWidth } from '_layouts/margins';
import { formatDate, formatTime } from '_shared/utils/dateUtil';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { capitaliseString } from '_shared/utils/stringUtil';
import { userPermissionTypes } from '_shared/constants/user';
import { userPermissionsState } from '_shared/globalState/atoms';
import { checkPermission } from '_shared/utils/permissions';
import { useRecoilValue } from 'recoil';
import CountdownBadge from '_shared/components/CountdownBadge';

export default function FlaggedTable(props) {
  const { data, headerTop, openReviewPage, navigate } = props;

  const { permissions } = useRecoilValue(userPermissionsState);

  const { addToast } = Toast();

  if (isEmpty(data))
    return (
      <Box mt={5} px={3}>
        <Alert message="No flagged points to show" status="info" />
      </Box>
    );

  return (
    <Box maxW={`${mainAreaMaxWidth}px`}>
      <Box overflowX={{ base: 'auto', md: 'initial' }}>
        <Table size="md" fontSize="sm" fontWeight="normal" color="grey.500">
          <Thead position="sticky" top={headerTop} zIndex={2} bg="white">
            <Tr>
              <HeaderCell>Players</HeaderCell>
              <Th whiteSpace="nowrap" height={8}>
                Match ID
              </Th>
              <Th whiteSpace="nowrap" height={8}>
                Tournament ID
              </Th>
              <HeaderCell>Score</HeaderCell>
              <HeaderCell>Set</HeaderCell>
              <HeaderCell>Game</HeaderCell>
              <HeaderCell>Point</HeaderCell>
              {checkPermission(userPermissionTypes.POINT_STATUS, permissions) && <HeaderCell>Status</HeaderCell>}
              <HeaderCell>Collector</HeaderCell>
              <HeaderCell>Time Flagged</HeaderCell>
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((item) => (
              <Tr
                _hover={{ bg: 'primary.25' }}
                cursor="pointer"
                onClick={() => {
                  if (checkPermission(userPermissionTypes.REVIEW, permissions))
                    openReviewPage(item.match_id, item.point_join_key, navigate, item.time_flagged);
                  else addToast({ message: 'You do not have permission to view this content', status: 'warning' });
                }}
                key={item.point_join_key}
              >
                <Td whiteSpace="nowrap">{item?.match_info?.players}</Td>
                <Td whiteSpace="nowrap">{item?.match_id}</Td>
                <Td whiteSpace="nowrap">{item?.comp_id}</Td>
                <Td whiteSpace="nowrap">
                  {`${item?.flagged_point_score?.game_score} ${item?.flagged_point_score?.point_score}`}
                </Td>
                <Td>{item?.match_info?.set}</Td>
                <Td>{item?.match_info?.game}</Td>
                <Td>{item?.match_info?.point}</Td>
                {checkPermission(userPermissionTypes.POINT_STATUS, permissions) && (
                  <Td>{capitaliseString(item?.data_status)}</Td>
                )}
                <Td>{item?.collected_by}</Td>
                <Td whiteSpace="nowrap">
                  <CountdownBadge flaggedTime={item.time_flagged} />
                  {`${formatDate(item.time_flagged)} ${formatTime(item.time_flagged)}`}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}

FlaggedTable.propTypes = {
  data: PropTypes.array,
  headerTop: PropTypes.object,
  openReviewPage: PropTypes.func,
  navigate: PropTypes.func
};

const HeaderCell = ({ children }) => <Th height={8}>{children}</Th>;
